import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyDO6lwvcEn3qQ0QotZXzZ7Rslcem8bpgok",
    authDomain: "sstream-eacf3.firebaseapp.com",
    databaseURL: "https://sstream-eacf3-default-rtdb.firebaseio.com",
    projectId: "sstream-eacf3",
    storageBucket: "sstream-eacf3.appspot.com",
    messagingSenderId: "1031018526563",
    appId: "1:1031018526563:web:698a600765e92a19a8c556",
    measurementId: "G-DZKBTQ481D"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);


  //COMMENT BEFORE DEPLOYING
  // firebase.appCheck().activate("985f35b1-54b9-4d33-8648-54dc13190610",
  // true);

  //UNCOMMENT BEFORE DEPLOYING
  const appCheck = firebase.appCheck();
appCheck.activate(
  '6LdbfWwfAAAAAD4OoQd7Tid3PlhUzg3rNesxOgRl',
  true)
  
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;
  const auth = firebaseApp.auth();
  const rtdb = firebaseApp.database();
  const db = firebaseApp.firestore();
  const analytics = firebase.analytics(firebaseApp);

  export {timestamp, auth, rtdb, db, analytics};
  // export default firebaseApp.firestore();