//import Vue from "vue";
//import { createStore } from 'vuex';
import Vuex from 'vuex';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import db from "../components/firebase/firebaseInit";
import {timestamp, auth, rtdb, db} from "../components/firebase/firebaseInit.js";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

//Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userRole: null,
    profileEmail: null,
    profileLastName: null,
    profileFirstName: null,
    profileId: null,
    profileInitials: null,
    emailVerified: null,
    targetStream: null,
    currentLocation: null,
    userClaims: null,
    chatPerParticipantVolume: [1],
    currentIpAddress: null,
    deviceId: null,
    currentStreamRoom: null,
    currentStreamRoomId: null,
    triggerLogout: null,
    kickuser: false,
    currentCameraInput: null,
    currentMicrophoneInput: null
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    profileId: state => state.profileId,
  },
  mutations: {
    setProfileInfo(state, doc) {
    state.profileId = doc.id;
    state.profileEmail = doc.data().email;
    state.profileFirstName = doc.data().firstName;
    state.profileLastName = doc.data().lastName;
    state.userRole = doc.data().userRole;
    state.emailVerified = doc.data().emailVerified;
    },
    updateUser(state, payload){
      state.user = payload;
    },
    setProfileInitials(state) {
      state.profileInitials = 
      state.profileFirstName.match(/(\b\S)?/g).join("") +
      state.profileLastName.match(/(\b\S)?/g).join("");
    },
    resetProfileInfo(state){
      state.profileId = null;
      state.profileEmail = null;
      state.profileFirstName = null;
      state.profileLastName = null;
      state.userRole = null;
      state.profileInitials = null;
      state.emailVerified = null;
      state.targetStream = null;
      state.userClaims = null;
      state.currentLocation = null;
      state.triggerLogout= null;
    },
    targetStream: (state, value) =>
    value ? (state.targetStream = value) : (state.targetStream = ""),
    userClaims: (state, value) =>
    state.userClaims = value
  },

  actions: {
   async getCurentUser({ commit }) {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
auth
              .currentUser.getIdTokenResult()
              .then(async tokenResult => {
                  const dataBase = await db.collection("users").doc(auth.currentUser.uid);
                  const dbResults = await dataBase.get();
                  commit("setProfileInfo", dbResults);
                  commit("setProfileInitials");
              });
      }
  });

    },
    logOut({ commit }) {
      commit("resetProfileInfo");
    },
  },
  modules: {
  },
})
