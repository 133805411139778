<template>
  <div class="form-wrap background">
    <form class="login" v-on:keyup.enter.prevent="logIn">
      <div class="inputs">
        <div class="input">
          <label>Username</label>
          <input type="text" placeholder="Email" v-model="email" />
        </div>
        <div class="input">
          <label>Password</label>
          <input type="password" placeholder="Password" v-model="password" />
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
        <router-link class="forgot-password" :to="{ name: 'Forgot Password' }"
          >Forgot your password?</router-link
        >
        <button v-if="displayVerifyEmailButton == false" @click.prevent="logIn">LOG IN</button>
        <button v-if="displayVerifyEmailButton == true" @click.prevent="sendVerificationEmail">SEND EMAIL VERIFICATION</button>
      </div>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { mapState } from 'vuex';
import {
  timestamp,
  auth,
  rtdb,
  db,
  analytics
} from "../components/firebase/firebaseInit";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      userRole: "",
      error: "",
      errorMsg: "",
      displayVerifyEmailButton: false
    };
  },
  computed: mapState(['profileId']),
    watch: {
    profileId(newValue, oldValue) {
      // console.log(`Updating from ${oldValue} to ${newValue}`);

      if (newValue == null) {
        return;
      } else {

      firebase.auth().currentUser.getIdTokenResult()
       .then(async ({claims}) => {
         this.$store.commit("userClaims", claims);
        // console.log(claims)
        if (this.$store.state.emailVerified) {
                  // console.log("I RAN");
                  analytics.logEvent('login');
                  if (this.$store.state.targetStream !== null) {
                    this.$router.push( '/streamroom?rid=' + this.$store.state.targetStream );
                  } else if (this.$store.state.userClaims.admin) {
                    this.$router.push({ name: "Admin Panel" });
                    console.log("Going to Admin Panel");
                  } else if (this.$store.state.userClaims.broadcaster) {
                    this.$router.push({ name: "Stream Browser" });
                    console.log("Going to Stream Browser");
                  } else if (this.$store.state.userClaims.viewer) {
                    this.$router.push({ name: "Stream Browser" });
                    console.log("Going to Stream Browser");
                  }
                  } 
                  else if (this.$store.state.emailVerified == false) {
                    this.email = this.$store.state.profileEmail;
                    // console.log (firebase.auth().currentUser.emailVerified)
                              this.error = true;
                              this.errorMsg = "Please verify your email. A verification email has been sent to your email address.";
                              this.displayVerifyEmailButton = true
                  }
                });
            }
    },
    },
  created() {
    if (firebase.auth().currentUser && this.$store.state.emailVerified) {
      // console.log("HAHA")
      this.$router.replace("/browse")
    }
if (this.$store.state.emailVerified == false) {
      this.email = this.$store.state.profileEmail;
      this.error = true;
      this.errorMsg = "Please verify your email. A verification email has been sent to your email address.";
      this.displayVerifyEmailButton = true
    }
  },
  methods: {
        sendVerificationEmail() {
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(() => {
          console.log("EMAIL SENT");
        });

        this.displayVerifyEmailButton = false;
        // firebase.auth().onAuthStateChanged((user) => {
        //  user.sendEmailVerification(); 
        // });
    },
    logIn() {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {


          // this.$store.dispatch("getCurentUser").then (() => {
          // if (this.$store.state.userRole == "viewer") {
          //   this.$router.push({ name: "Stream Browser" });
          //   console.log('going to Stream Browser');

          // } else if (this.$store.state.userRole == "admin") {
          //   this.$router.push({ name: "Admin Panel" });
          //   console.log('Going to Admin Panel');
          // }
          // });
          // this.error = false;
          // this.errorMsg = "";
        })
        .catch((err) => {
          this.error = true;
          this.errorMsg = err.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: end;
  width: 100%;
  margin: 0 auto;
  background-image: url("../assets/loginbackground.jpg");

  .background {
    background-size: cover;
    background-image: url("../assets/loginbackground.jpg");
  }
}

.login {
left: auto;
    width: 300px !important;
    background-color: #2f323c;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    height: 100%;
}

.input {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-weight: 100;
  justify-content: start;
  align-items: start;
  width: 100%;
  color: #e1ecff;
  margin-bottom: 20px;

  label {
    color: #e1ecff;
    font-weight: 400;
    font-size: 0.8em;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 1em;
  font-weight: 100;
  width: 80%;
}

input {
  padding-left: 5px;
  width: 100%;
  font-size: 1em;
  border-radius: 3px;
  background-color: #262830;
  border: solid 1px #17181a !important;
  height: 43px !important;
  color: #e5e5e5e5;
}

button {
  background-color: #2f323c;
  border: 1px solid #9296a1;
  height: 38px;
  width: max-content;
  margin-top: 25px;
  margin-right: auto;
  color: #e1ecff;
  font-weight: 400;
  font-size: 1em;
}

button:hover {
  background-color: #262830;
}

.error {
  color: #c93521;
  font-weight: 400;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.forgot-password {
  font-family: roboto;
  color: #e1ecff;
  font-weight: 400;
  font-size: 0.8em;
}
</style>