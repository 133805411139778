<template>
  <div id="sstreamapp">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import DeviceDetector from "device-detector-js";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../src/components/firebase/firebaseInit.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "app",
  data() {
    return {
      registration: null,
      updateExists: null,
      navigation: null,
      heartbeat: setInterval(() => this.sendHeartbeatGlobal(), 60000),
      currentDevice: null,
      geoLocation: null,
      pendingDeviceAuthorization: null,
      currentStreamRoomId: null,
      currentStreamRoom: null,
    };
  },
  components: {},
  watch: {
    "$store.state.triggerLogout": function () {
      if (this.$store.state.triggerLogout) {
        console.log("LOGOUT TRIGGERED");
        this.$store.state.triggerLogout = false;
        this.logOut();
        return;
      } else {
        this.$store.state.triggerLogout = false;
        return;
      }
    },
    updateExists(newVal, oldVal) {
      if (newVal) {
        this.updateMessage();
      }
    },
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });

    let deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    this.currentDevice = deviceDetector.parse(userAgent);
    firebase.auth().onAuthStateChanged((user) => {
      // Check if user is logged in
      if (user != null) {
        let sentDate = Date.now();
        let mailID = sentDate + user.email + "-ndd";

        if (user.emailVerified) {
          db.collection("users").doc(user.uid).update({
            emailVerified: true,
          });
        }

        // If user is logged in get user details
        db.collection("users")
          .doc(user.uid)
          .get()
          .then(async (doc) => {
            this.$store.state.profileId = doc.id;
            this.$store.state.profileEmail = doc.data().email;
            this.$store.state.profileFirstName = doc.data().firstName;
            this.$store.state.profileLastName = doc.data().lastName;
            this.$store.state.userRole = doc.data().userRole;
            this.$store.state.emailVerified = doc.data().emailVerified;
            this.pendingDeviceAuthorization =
              doc.data().pendingDeviceAuthorization;

            // Check if device has been assigned UUID
            if (this.$store.state.deviceId == null) {
              this.$store.state.deviceId = uuidv4();

              // console.log("DEVICE ID GENERATED: ", this.$store.state.deviceId);

              // Mark user as having a pending device authorization
              db.collection("users").doc(doc.id).update({
                pendingDeviceAuthorization: true,
              });

              // Send email to user to initiate device approval workflow
              db.collection("mail")
                .doc(mailID)
                .set({
                  sentDate: sentDate,
                  to: user.email,
                  userId: user.uid,
                  expired: false,
                  newDeviceId: this.$store.state.deviceId,
                  template: {
                    name: "newdevice",
                    data: {
                      fullName:
                        this.$store.state.profileFirstName +
                        " " +
                        this.$store.state.profileLastName,
                      registrationLink:
                        "https://sstream.app/device-approval?id=" + mailID,
                      deviceType: this.currentDevice.device.type,
                      deviceBrand: this.currentDevice.device.brand,
                      deviceOS: this.currentDevice.os.name,
                      deviceBrowser: this.currentDevice.client.name,
                      deviceBrowserVer: this.currentDevice.client.version,
                    },
                  },
                })
                .then(() => {
                  console.log("NEW DEVICE EMAIL SENT");
                  this.$router.replace("newdevice");
                })
                .catch((error) => {});

              // If the device has been assigned a UUID
            } else if (this.$store.state.deviceId != null) {
              // Check current logged in user if they have approved this device
              db.collection("users")
                .doc(user.uid)
                .collection("approvedDevices")
                .doc(this.$store.state.deviceId)
                .get()
                .then((doc) => {
                  // If device has been approved
                  if (doc.exists) {
                    console.log("DEVICE APPROVED");
                    this.deviceApproved = true;

                    // Change pending Device auth to false
                    db.collection("users").doc(user.uid).update({
                      pendingDeviceAuthorization: false,
                    });

                    // Activate RTDB
                    rtdb.goOnline();

                    let rtTracking = rtdb.ref("realtimeTracking/" + user.uid);

                    rtTracking.update({
                      deviceId: this.$store.state.deviceId,
                    });

                    const getIP = firebase
                      .functions()
                      .httpsCallable("getIPAddress");

                    // Initiate Client IP check
                    getIP()
                      .then((result) => {
                        this.$store.state.currentIpAddress = result.data;

                        const geoLocate = firebase
                          .functions()
                          .httpsCallable("geoLocate");

                        // Initiate client Geolocation check
                        geoLocate()
                          .then((result) => {
                            this.geoLocation = result;
                            // deepcode ignore NoEffectExpression: <triggers a heartbeat function>
                            // Start tracking heartbeat

                            let logAccess = rtdb.ref("masterLog/").push();

                            rtTracking.get().then((snapshot) => {
                              if (snapshot.val().active) {
                                return;
                              } else if (!snapshot.val().active) {
                                rtTracking.update({
                                  lastLoginTime:
                                    firebase.database.ServerValue.TIMESTAMP,
                                  heartbeat: Date.now(),
                                  active: true,
                                  ipAddress: this.$store.state.currentIpAddress,
                                  currentLocation:
                                    this.$store.state.currentLocation,
                                  currentStreamRoom: this.currentStreamRoom,
                                  currentStreamRoomId: this.currentStreamRoomId,
                                  deviceId: this.$store.state.deviceId,
                                  currentDevice: this.currentDevice,
                                  geoLocation: this.geoLocation,
                                  user: user.email,
                                });

                                logAccess.set({
                                  type: "login",
                                  user: user.email,
                                  userId: user.uid,
                                  ipAddress: this.$store.state.currentIpAddress,
                                  deviceId: this.$store.state.deviceId,
                                  currentDevice: this.currentDevice,
                                  geoLocation: this.geoLocation,
                                  sendDate:
                                    firebase.database.ServerValue.TIMESTAMP,
                                });
                              }
                            });
                            this.heartbeat;
                          })
                          .catch((e) => {
                            console.log(e);
                          });

                        // rtTracking.onDisconnect().update({
                        //   lastDisconnectTime:
                        //     firebase.database.ServerValue.TIMESTAMP,
                        //   currentLocation: null,
                        //   deviceId: null,
                        //   active: false,
                        // });

                        // let logAccessDisconnect = rtdb.ref("masterLog/").push();

                        // logAccessDisconnect.onDisconnect().set({
                        //   type: "logout",
                        //   user: user.email,
                        //   userId: user.uid,
                        //   ipAddress: this.$store.state.currentIpAddress,
                        //   deviceId: this.$store.state.deviceId,
                        //   currentDevice: this.currentDevice,
                        //   geoLocation: this.geoLocation,
                        //   streamRoomName: this.currentStreamRoom,
                        //   streamRoom: this.currentStreamRoomId,
                        //   sendDate: firebase.database.ServerValue.TIMESTAMP,
                        // });

                        let deviceIdCheck = firebase
                          .database()
                          .ref("realtimeTracking/" + user.uid);

                        //Multi login check
                        deviceIdCheck.on("value", (snapshot) => {
                          // console.log(snapshot.val().deviceId)
                          // console.log(this.$store.state.deviceId)

                          // If multiple login is detected logout
                          if (
                            snapshot.val().deviceId !=
                              this.$store.state.deviceId &&
                            snapshot.val().deviceId != null
                          ) {
                            deviceIdCheck.off();
                            this.$store.dispatch("logOut");
                            firebase
                              .auth()
                              .signOut()
                              .then(() => {
                                console.log("LOGOUT");
                                this.$router.replace("/");
                              })
                              .catch((error) => {});
                          } else {
                            return;
                          }
                        });
                      })
                      .catch((error) => {});

                    // If device is not authorized
                  } else if (doc.exists == false) {
                    // If pending device authorization is false email user to authorize device and set pending device auth to true
                    if (
                      this.pendingDeviceAuthorization == null ||
                      this.pendingDeviceAuthorization == false
                    ) {
                      db.collection("mail")
                        .doc(mailID)
                        .set({
                          sentDate: sentDate,
                          to: user.email,
                          userId: user.uid,
                          expired: false,
                          newDeviceId: this.$store.state.deviceId,
                          template: {
                            name: "newdevice",
                            data: {
                              fullName:
                                this.$store.state.profileFirstName +
                                " " +
                                this.$store.state.profileLastName,
                              registrationLink:
                                "https://sstream.app/device-approval?id=" +
                                mailID,
                              deviceType: this.currentDevice.device.type,
                              deviceBrand: this.currentDevice.device.brand,
                              deviceOS: this.currentDevice.os.name,
                              deviceBrowser: this.currentDevice.client.name,
                              deviceBrowserVer:
                                this.currentDevice.client.version,
                            },
                          },
                        })
                        .then(() => {
                          db.collection("users").doc(user.uid).update({
                            pendingDeviceAuthorization: true,
                          });
                          console.log("NEW DEVICE EMAIL SENT");
                          this.$router.replace("newdevice");
                        })
                        .catch((error) => {});
                    }

                    console.log("DEVICE IS NOT APPROVED");
                    this.deviceApproved = false;
                    this.$router.replace("newdevice");
                  }
                })
                .catch((error) => {});
            }
          })
          .catch((error) => {});

        // if user logs out clear heartbeat interval and set RTDB to offline
      } else if (user == null) {
        // this.$store.dispatch("logOut");
        clearInterval(this.heartbeat);
        rtdb.goOffline();
      }
    });
  },
  mounted() {
    // this.updateMessage();
  },
  methods: {
    updateMessage() {
      createToast(
        {
          title: "SITE UPDATE",
          description: "Please refresh the page to load update.",
        },
        {
          timeout: -1,
          showCloseButton: false,
          swipeClose: false,
          showIcon: true,
          position: "top-center",
          type: "info",
          toastBackgroundColor: "RGB(233, 60, 45, 0.8)",
        }
      );
      this.refreshApp();
    },
    updateAvailable(event) {
      console.log(event);
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      console.log("test");
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    sendHeartbeatGlobal() {
      if (this.$store.state.currentLocation == "Stream Browser") {
        this.currentStreamRoom = "N/A";
        this.currentStreamRoomId = "N/A";
      } else if (this.$store.state.currentLocation == "Admin Panel") {
        this.currentStreamRoom = "N/A";
        this.currentStreamRoomId = "N/A";
      } else {
        this.currentStreamRoom = this.$store.state.currentStreamRoom;
        this.currentStreamRoomId = this.$store.state.currentStreamRoomId;
      }

      rtdb
        .ref("realtimeTracking/" + this.$store.state.profileId)
        .update({
          user: this.$store.state.profileEmail,
          ipAddress: this.$store.state.currentIpAddress,
          deviceId: this.$store.state.deviceId,
          currentDevice: this.currentDevice,
          geoLocation: this.geoLocation,
          currentStreamRoom: this.currentStreamRoom,
          currentStreamRoomId: this.currentStreamRoomId,
          active: true,
          currentLocation: this.$store.state.currentLocation,
          heartbeat: Date.now(),
        })
        .then(() => {})
        .catch((error) => {});
    },
    async logOut() {
      let rtTracking = rtdb.ref(
        "realtimeTracking/" + this.$store.state.profileId
      );

      rtTracking.update({
        lastDisconnectTime: firebase.database.ServerValue.TIMESTAMP,
        active: false,
        user: this.$store.state.profileEmail,
      });

      let logAccess = rtdb.ref("masterLog/").push();

      logAccess.set({
        type: "logout",
        user: this.$store.state.profileEmail,
        userId: this.$store.state.profileId,
        ipAddress: this.$store.state.currentIpAddress,
        deviceId: this.$store.state.deviceId,
        currentDevice: this.currentDevice,
        geoLocation: this.geoLocation,
        streamRoomName: this.currentStreamRoom,
        streamRoom: this.currentStreamRoomId,
        sendDate: firebase.database.ServerValue.TIMESTAMP,
      });

      firebase
        .auth()
        .signOut()
        .then(() => {
          firebase.auth().onAuthStateChanged((userAuth) => {
            if (!userAuth) {
              this.$store.dispatch("logOut");
              this.$router.replace("/");
            }
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  background: #2c3e50;
  color: #ffffff;
  overflow: hidden;
  margin: 0px;
}
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 32px;
  font-weight: 300;
  color: #eff3ff;
}

h2 {
  font-size: 24px;
  font-weight: 300;
}

h5 {
  font-size: 12px;
  font-weight: 800;
  color: RGB(123, 138, 173);
  margin: 0px;
}

a {
  color: inherit;
}

h4 {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  color: #e5ecff;
  position: relative;
  margin-block-start: 10px;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #383c4a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2f323c;
}

::-webkit-scrollbar-track {
  background: #1c1d23;
  border-radius: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #262830 !important;
}
</style>
