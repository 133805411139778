import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue'
// import '../components/firebase/firebaseInit.js'
import store from '../store/index'
// import firebase from "firebase/compat/app"
// import StreamBrowser from '../views/StreamBrowser.vue'
import Login from '../views/Login.vue'
// import ForgotPassword from '../views/ForgotPassword.vue'
// import AdminPanel from '../views/AdminPanel.vue'
// import InviteRegistration from '../views/inviteRegistration.vue'
// import EmailHandling from "../views/EmailHandling.vue"
// import StreamRoom from "../views/StreamRoom.vue"
import {timestamp, auth, rtdb, db} from "../components/firebase/firebaseInit.js";
import firebase from "firebase/compat/app";
import { email } from '@vuelidate/validators';


const AdminPanel = () => import('../views/AdminPanel.vue')
const StreamBrowser = () => import('../views/StreamBrowser.vue')
const ForgotPassword = () => import('../views/ForgotPassword.vue')
const StreamRoom = () => import('../views/StreamRoom.vue')
const InviteRegistration = () => import('../views/inviteRegistration.vue')
const EmailHandling = () => import('../views/EmailHandling.vue')
const NewDevice = () => import('../views/NewDevice.vue')
const DeviceApproval = () => import('../views/DeviceApproval.vue')


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: '/newdevice',
    name: 'New Device',
    component: NewDevice,
    meta: {
      title: "New Device",
    },
  },
  {
    path: '/device-approval',
    name: 'Device Approval',
    component: DeviceApproval,
    meta: {
      title: "Device Approval",
    },
  },
  {
    path: '/browse',
    name: 'Stream Browser',
    component: StreamBrowser,
    meta: {
      title: "Stream Browser",
      viewerAuth: true,
      adminAuth: false,
      broadcastAuth: true
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
      guest: true
    },
  },
  {
    path: '/admin-panel',
    name: 'Admin Panel',
    component: AdminPanel,
    meta: {
      title: "Admin Panel",
      adminAuth: true,
    },
  },
  {
    path: '/invite-registration',
    name: 'Invite Registration',
    component: InviteRegistration,
    meta: {
      title: "Invite Registration",
      guest: true
    },
  },
  {
    path: '/streamroom',
    name: 'Stream Room',
    component: StreamRoom,
    meta: {
      title: "Stream Room",
      viewerAuth: true,
      adminAuth: true,
      broadcastAuth: true
    },
  },
  {
    path: '/email-verification',
    name: 'Email Verification',
    component: EmailHandling,
    meta: {
      title: "Email Verification",
      adminAuth: true,
      viewerAuth: true,
      broadcastAuth: true
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  const viewerAuth = to.matched.some(record => record.meta.viewerAuth);
  const broadcastAuth = to.matched.some(record => record.meta.broadcastAuth);
  const adminAuth = to.matched.some(record => record.meta.adminAuth);
  let claims = store.state.userClaims;
  let emailVerified = store.state.emailVerified;
  
  await auth.onIdTokenChanged(userAuth => {
    if (userAuth) {  
      store.dispatch("getCurentUser");
      firebase.auth().currentUser.getIdTokenResult()
        .then(async ({claims}) => {
          store.commit("userClaims", claims);
        })
      }
    });

    

 if (to.path == "/streamroom" && to.query.rid !== undefined) {
  store.state.targetStream = to.query.rid
 } 

 if (to.fullPath == '/' && claims == null) {
    next();
    return ;
  }  else if (to.path == '/forgot-password' || to.path == '/email-verification' || to.path == '/invite-registration' || to.path == '/device-approval' ) {
    next();
    return;
  } else if (to.path == '/newdevice' && claims != null) {
    next();
    return;
  }
  if (emailVerified == true) {
    if (store.state.profileId !== null) {
    if (to.path == "/streamroom" && claims.admin) {
      next();
    } else if (to.path == "/streamroom") {
      let streamRoomId = store.state.targetStream
      await db.collection("users").doc(store.state.profileId).collection("roomAccess").doc(streamRoomId).get().then((doc) => {
        if (doc.exists) {
          next();
          return
        } else {
          store.state.targetStream = null;
          if (from.path == "/"){
            if (claims.admin) {
              next('/admin-panel');
              return;
          } else if (claims.broadcaster || claims.viewer) {
            next('/browse')
            return;
          }
          return;
        }
      }
      })
    } else if (adminAuth && claims.admin) {
      next();
      return 
  
    } else if (broadcastAuth && claims.broadcaster) {
      next();
      return
  
    } else if (viewerAuth && claims.viewer) {
      next();
      return 
  
    } else if (to.path = '/' && claims.admin) {
      next('/admin-panel');
      return
  
    } else if (to.path = '/' && claims.viewer) {
      next('/browse');
      return
  
    } else if (to.path = '/' && claims.broadcaster) {
      next('/browse');
      return
  
    } else {
      next()
      return
    }

  } else if (store.state.profileId == null) {
    next('/');

     return
  }
} 
else if (emailVerified == false) {
  console.log("test")
  if (to.fullPath != '/') {

    next('/');
    return;
  } else if (to.fullPath == '/' ) {

    next();
    return;
  }
} else if (emailVerified == null) {
  if (to.fullPath != '/') {

    next('/');
    return;
  } else if (to.fullPath == '/' ) {

    next();
    return;
  }
}
})


export default router
